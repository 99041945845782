import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import OneTile from "./oneTile"

const Products = () => {
  const data = useStaticQuery(graphql`
    query {
      allPlakatyYaml(sort: { fields: orderNum, order: ASC }) {
        edges {
          node {
            id
            name
            cena
            category
            fields {
              slug
            }
            pictures {
              childImageSharp {
                original {
                  src
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  `)
  // console.log(
  //   data.allPlakatyYaml.edges.map(({ node }) =>
  //     node.pictures.map(({ childImageSharp }) => childImageSharp.original.src)
  //   )
  // )

  return (
    <div className="products-main">
      {data.allPlakatyYaml.edges.map(({ node }) => (
        <OneTile
          key={node.id}
          name={node.name}
          imgUrl={node.pictures[0].childImageSharp.original.src}
          imgWidth={node.pictures[0].childImageSharp.original.width}
          imgHeight={node.pictures[0].childImageSharp.original.height}
          url={node.fields.slug}
          cena={node.cena}
        />
      ))}
    </div>
  )
}
export default Products
